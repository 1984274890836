import React, { useState } from 'react';
import { MyAppBarStyles } from './MyAppBarStyles';

import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Hidden, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ContactsIcon from '@material-ui/icons/Contacts';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';

const StyledMenu = withStyles({
	paper: {
	  	border: '1px solid #d3d4d5',
	}
})((props) => (
	<Menu
	  	elevation={0}
	  	getContentAnchorEl={null}
	  	anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center',
	  	}}
	  	transformOrigin={{
			vertical: 'top',
			horizontal: 'center',
	  	}}
	  	{...props}
	/>
));
  
const StyledMenuItem = withStyles((theme) => ({
	root: {
	  	'&:focus': {
			backgroundColor: theme.palette.common.black,
				'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
		  			color: theme.palette.common.white,
				},
	  	},
	},
}))(MenuItem);

export const MyAppBar = () => {
	const classes = MyAppBarStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => setAnchorEl(event.currentTarget);
	const handleClose = () => setAnchorEl(null);

	return (
		<div className={classes.root} >
			<AppBar position="fixed" color="inherit">
				<Toolbar>
				<Hidden xlUp>
					<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" aria-controls="customized-menu" aria-haspopup="true" onClick={handleClick}>
						<MenuIcon />
					</IconButton>
				</Hidden>
				
				<StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
					<Link href="#quienes-somos" color="textPrimary" underline="none" onClick={ handleClose }>
						<StyledMenuItem>
							<ListItemIcon><ContactsIcon fontSize="small" /></ListItemIcon>
							<ListItemText primary="Quiénes somos" />
						</StyledMenuItem>
					</Link>
					<Link href="#tratamientos-faciales" color="textPrimary" underline="none" onClick={ handleClose }>
						<StyledMenuItem>
							<ListItemIcon><EmojiEmotionsIcon fontSize="small" /></ListItemIcon>
							<ListItemText primary="Tratamientos faciales" />
						</StyledMenuItem>
					</Link>
					<Link href="#tratamientos-corporales" color="textPrimary" underline="none" onClick={ handleClose }>
						<StyledMenuItem>
							<ListItemIcon><EmojiPeopleIcon fontSize="small" /></ListItemIcon>
							<ListItemText primary="Tratamientos corporales" />
						</StyledMenuItem>
					</Link>
					<Link href="#tarjeta-regalo" color="textPrimary" underline="none" onClick={ handleClose }>
						<StyledMenuItem>
							<ListItemIcon><CardGiftcardIcon fontSize="small" /></ListItemIcon>
							<ListItemText primary="Tarjeta Regalo" />
						</StyledMenuItem>
					</Link>
					<Link href="#magazine" color="textPrimary" underline="none" onClick={ handleClose }>
						<StyledMenuItem>
							<ListItemIcon><ImportContactsIcon fontSize="small" /></ListItemIcon>
							<ListItemText primary="Magazine" />
						</StyledMenuItem>
					</Link>
					<Link href="#premios" color="textPrimary" underline="none" onClick={ handleClose }>
						<StyledMenuItem>
							<ListItemIcon><EmojiEventsIcon fontSize="small" /></ListItemIcon>
							<ListItemText primary="Premios" />
						</StyledMenuItem>
					</Link>
				</StyledMenu>

				<Typography variant="h6" className={classes.title}>
					<Link href="#index" color="inherit" underline="none">Simplemente Diosa</Link>
				</Typography>
				<img src="/assets/premier_resultado.png" alt="" />
				
				<Hidden lgDown>
					<Button color="inherit" href="#quienes-somos"><ContactsIcon fontSize="large" />Quiénes somos</Button>
					<Button color="inherit" href="#tratamientos-faciales"><EmojiEmotionsIcon fontSize="large" />Tratamientos faciales</Button>
					<Button color="inherit" href="#tratamientos-corporales"><EmojiPeopleIcon fontSize="large" />Tratamientos corporales</Button>
					<Button color="inherit" href="#tarjeta-regalo"><CardGiftcardIcon fontSize="large" />Tarjeta Regalo</Button>
					<Button color="inherit" href="#magazine"><ImportContactsIcon fontSize="large" />Magazine</Button>
					<Button color="inherit" href="#premios"><EmojiEventsIcon fontSize="large" />Premios</Button>
				</Hidden>
				
				</Toolbar>
			</AppBar>
		</div>
	);
}
